<template>
    <div class="footer">
        <div class="footer-content">版权所有 深圳市锐曼智能装备有限公司 未经许可 严禁复制粤ICP备15031246号-2</div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    setup() {

    },
})
</script>

<style lang="less" scoped>
.footer {
    background-color: #fff;
    height: 80px;
    .footer-content {
        width: 1100px;
        margin: 0 auto;
        line-height: 80px;
        font-size: 8px;
        text-align: center;
        // color: #888;
    }
}
</style>
