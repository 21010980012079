<template>
  <el-affix :offset="0">
    <Header />
  </el-affix>

  <div class="content-box">
    <Menu class="left" />
    <div class="right">
      <!-- <Content> </Content> -->
      <keep-alive>
        <transition name="move" mode="out-in">
          <router-view></router-view>
        </transition>
      </keep-alive>
    </div>
    <!-- <Editor /> -->
  </div>
  <Footer />
   <!-- <iframe src="http://rmbot.cn" width="900px" height="900px" scrolling="auto" frameborder="0" id="iframe"></iframe> -->
  <el-backtop />
</template>

<script>
import Header from "@/components/Header.vue";
import Menu from "../Menu/Menu.vue";
// import Content from "../Content/Content.vue";
import Footer from "@/components/footer.vue"
import { defineComponent, onMounted, ref, reactive } from "vue";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "Home",
  components: { Header, Menu, Footer },
  setup() {
    const route = useRoute();
    onMounted(() => {
      // console.log("route==>", route.fullPath);
    });
    return {
      route,
    };
  },
});
</script>

<style lang="less" scoped>
.content-box {
  // position: relative;
  // left: 0;
  // top: 0;
  width: 1100px;
  margin: 0 auto;
  padding: 16px 0;
  overflow: hidden;
  min-height: calc(100vh - 237px);
  .left {
    width: 230px;
    float: left;
    border: 1px solid #e5e5e5;
    min-height: 590px;
  }
  .right {
    float: right;
    margin-right: 4px;
    width: 845px;
    min-height: 592px;
    background-color: #fff;
    border: 1px solid #e5e5e5;
  }
}
</style>
