<template>
  <div class="menu">
    <div class="menu-logo">
      <img src="../../assets/img/logoIcon.png" alt />
      <span>问题&解答</span>
    </div>
    <el-menu
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
      @select="handleSelect"
      :unique-opened="true"
      @open="handleOpen"
      @close="handleClose"
      :default-active="defaultIndexMenu"
      active-text-color="#00ace9"
    >
      <el-menu-item index="hot">
        <i class="iconfont icon-shouye"></i>
        <span>热门问题</span>
      </el-menu-item>
      <div v-for="(item, index) in menuData" :key="index">
        <el-sub-menu :index="item.id">
          <template #title>
            <i class="iconfont" :class="item.menuIcon"></i>
            <span>{{ item.menuName }}</span>
          </template>
          <div v-for="(subItem, subIndex) in item.children" :key="subIndex">
            <el-menu-item-group>
              <el-menu-item :index="subItem.id">
                <i class="iconfont" :class="subItem.menuIcon"></i>
                {{ subItem.menuName }}
              </el-menu-item>
            </el-menu-item-group>
          </div>
        </el-sub-menu>
      </div>
    </el-menu>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, reactive, toRefs,computed } from "vue";
import { getMenuList, getArticle, getHotPro } from "../../service/request";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      menuData: [],
    });
    const isCollapse = ref(false);
    const getHotProblem = () => {
      let parmas = {
        sort: "click_volume",
      };  
      getHotPro(parmas).then((res) => {
        store.commit("setArticleTitleList", res.data.data);
        store.commit("setContentLoading", false);
      });
    };
    const handleSelect = (index, indexPath, item) => {
      store.commit("setContentLoading", true);
      store.commit("setDefaultIndexMenu", index);
      if (index == "hot") {
        getHotProblem();
      } else {
        let parmas = {
          menuId: index,
          approved: 0,
        };
        getArticle(parmas).then((res) => {
          store.commit("setArticleTitleList", res.data.data);
          store.commit("setContentLoading", false);
        });
      }
      store.commit("setIsShowEditor", false);
      router.push({
        path: "/",
      });
    };
    const handleOpen = (key, keyPath) => {
      // console.log(key, keyPath);
    };
    const handleClose = (key, keyPath) => {
      // console.log(key, keyPath);
    };
    onMounted(() => {
      getMenuList().then((res) => {
        state.menuData = res.data.data;
        store.commit("setMenu", res.data.data);
      });
      getHotProblem();
    });
    return {
      ...toRefs(state),
      isCollapse,
      handleSelect,
      handleOpen,
      handleClose,
      defaultIndexMenu : computed(()=>store.state.defaultIndexMenu),
    };
  },
});
</script>

<style scoped lang="less">
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 220px;
  border: none;
}
.menu {
  background-color: #fff;
  .menu-logo {
    height: 90px;
    border-bottom: 1px solid #f5f5f5;
    line-height: 90px;
    text-align: center;
    img {
      vertical-align: middle;
      margin-left: -55px;
      margin-right: 10px;
      width: 50px;
      
    }
    span{
      font-size: 14px;
    }
  }
}
.iconfont {
  margin-right: 5px;
  width: 24px;
  text-align: center;
  font-size: 18px;
  vertical-align: middle;
}
</style>
