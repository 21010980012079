<template>
  <div class="header">
    <div class="main">
      <div class="left">
        <img src="../assets/img/logo.png" alt />
      
      </div>

      <div class="login_btn">
        <div class="btn-bell"
        v-show="user == 'admin' || user == 'device admin'"
         @click="handleBellBtn">
          <el-tooltip
            class="item"
            effect="dark"
            :content="bellVal ? '有新的反馈消息' : '反馈中心'"
            placement="bottom"
          >
            <el-badge :value="bellVal" class="item">
              <el-icon>
                <Bell style="width: 1.5em;height:1.5em" />
              </el-icon>
            </el-badge>
          </el-tooltip>
        </div>
        <el-dropdown @command="handleCommand" v-if="user !== null" style="cursor: pointer">
          <span class="el-dropdown-link">
            {{ userName }}
            <el-icon class="el-icon--right">
              <arrow-down />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-show="user == 'admin' || user == 'device admin'" command="0">编辑分类</el-dropdown-item>
              <el-dropdown-item v-show="user == 'admin'  || user == 'device admin'" command="1">添加问题</el-dropdown-item>
              <el-dropdown-item v-show="user == 'admin'  || user == 'device admin'" command="2">问题反馈</el-dropdown-item>
              <el-dropdown-item command="3">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-button v-if="!user" class="btn" type="primary" @click="login">登录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
import { ArrowDown, Bell } from "@element-plus/icons";
import { ElMessage, ElNotification } from "element-plus";
import { useStore } from "vuex";
export default defineComponent({
  name: "header",
  components: { ArrowDown, Bell },
  setup() {
    var timer //定时发心跳 保持连接
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      user: null,
      bellVal: "",
      userName: '',
      ws: ''
    });

    const login = () => {
      router.push({
        path: "/login",
      });
    };
    const logOut = () => {
      router.push({
        path: "/login",
      });
      ElMessage({
        message: "退出成功",
        type: "success",
      });
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("userName");
    };

    const add = () => {
      store.commit("setIsShowEditor", true);
      store.commit("setModifyId", 0);
      store.commit("setModifyContent", null);
      store.commit("setIsModifyStatus", false);
    };

    const handleBellBtn = () => {
      router.push({
        path: "/feedback",
      });
      state.bellVal = ''
    }

    const handleCommand = (command) => {
      switch (command) {
        case "0":
          //编辑分类
          router.push({
            path: "/menu",
          });
          break;
        case "1":
          //添加问题
          router.push({
            path: "/",
          });
          add()
          break;
        case "2":
          //游览反馈
          router.push({
            path: "/feedback",
          });
          break;
        case "3":
          //退出
          logOut();
          if (state.ws) {
            state.ws.close()
            clearInterval(timer)
          }
          break;

        default:
          break;
      }
    };

    const openNotify = () => {
      ElNotification({
        title: '通知',
        message: '有新的反馈消息',
        type: 'info',
        duration: 3000,
        onClick() {
          router.push({
            path: "/feedback",
          });
        }
      })
    }

    //webSocket
    const WebSocketFun = () => {
      let userId = new Date().getTime()//后端定义的 随机数 采用时间戳不会重复
      state.ws = new WebSocket(`wss://faq.rmbot.cn/ws/websocket/${userId}`);
      state.ws.onopen = () => {
        // console.log("open");
        timer = setInterval(() => {
          state.ws.send(JSON.stringify({
            msgType: 1,          //后端写死传的数据
            data: 'heartBeat'
          }));
        }, 5000);
      }
      // 当客户端收到服务端发来的消息 新消息
      state.ws.onmessage = (e) => {
        let data = JSON.parse(e.data)
        console.log(data);
        if (data.msgType == 2) {
          state.bellVal = 'new'
          openNotify()
        }
      }
      state.ws.onclose = (e) => {
        console.log("close");
        state.ws.close()
        clearInterval(timer)
      }
      state.ws.onerror = (e) => {
        console.log(e);
        state.ws.close()
        clearInterval(timer)
      }
    }

    onMounted(() => {
      const user = sessionStorage.getItem("user");
      const name = sessionStorage.getItem('userName')
      state.user = user;
      state.userName = name
      if (user == 'admin'  || user == 'device admin') {
        WebSocketFun()
      }
    });

    onBeforeUnmount(() => {
      if (state.ws) {
        state.ws.close()
        clearInterval(timer)
      }

    })
    return {
      login,
      handleCommand,
      add,
      ...toRefs(state),
      Bell,
      handleBellBtn,
      WebSocketFun,
      openNotify,
      timer
    };
  },
});
</script>

<style lang="less" scoped>
.header {
  height: 80px;
  width: 100%;
  background-color: #fff;
  margin: 0 auto;

  .main {
    width: 1100px;
    margin: 0 auto;
    overflow: hidden;
    .left {
      float: left;
      height: 80px;
      line-height: 80px;
      img {
        width: 189px;
        height: 44px;
        vertical-align: middle;
      }
    }
    .login_btn {
      float: right;
      line-height: 80px;
      height: 80px;
      position: relative;
      .btn {
        margin-left: 20px;
      }
      .btn-bell {
        line-height: 0;
        cursor: pointer;
        display: inline-block;
      }
    }
  }
}
.item {
  margin-right: 20px;
  margin-bottom: 10px;
}
</style>
